import React from "react"
import {ARCanvas, NFTMarker} from "react-three-arnft"
import * as THREE from 'three';
import {GLTFLoader} from 'three/examples/jsm/loaders/GLTFLoader';
import {useFrame, useLoader} from '@react-three/fiber';
import glb from './assets/facilityTestCube.glb';

const Model = () => {
    const clock = new THREE.Clock();
    const gltf = useLoader(GLTFLoader, glb);
    const mixer = new THREE.AnimationMixer(gltf.scene);

    console.log(gltf)

    gltf.scene.rotateX(Math.PI / 2);

    useFrame(() => mixer.update(clock.getDelta()));

    return <primitive object={gltf.scene}/>;
};

const App = () => {

    return (
        <ARCanvas
            interpolationFactor={1}
            onCreated={({gl}) => {
                gl.setSize(window.innerWidth, window.innerHeight)
            }}
        >
            {/*<NFTMarker url={["../data/marker/zedpro-geoinfo-nft-new.fset", "../data/marker/zedpro-geoinfo-nft-new.fset3", "../data/marker/zedpro-geoinfo-nft-new.iset"]}>*/}
            {/*<NFTMarker url={["../data/marker/zedpro-geoinfo-mova-logo.fset", "../data/marker/zedpro-geoinfo-mova-logo.fset3", "../data/marker/zedpro-geoinfo-mova-logo.iset"]}>*/}
            {/*  <NFTMarker url={["../data/marker/zedpro-geoinfo-nft.fset", "../data/marker/zedpro-geoinfo-nft.fset3", "../data/marker/zedpro-geoinfo-nft.iset"]}>*/}
            {/*<NFTMarker url={["../data/marker/pinball.fset", "../data/marker/pinball.fset3", "../data/marker/pinball.iset"]}>*/}
            {/*<NFTMarker url={["../data/marker/klcc.fset", "../data/marker/klcc.fset3", "../data/marker/klcc.iset"]}>*/}
            <NFTMarker url={["../data/marker/logi-air-daf-2.fset", "../data/marker/logi-air-daf-2.fset3", "../data/marker/logi-air-daf-2.iset"]}>
            {/**/}
            {/*<mesh scale={[200, 200, 10]}>*/}
                {/*  <boxGeometry args={[1, 1, 1]} />*/}
                {/*  <meshNormalMaterial opacity={0.5} transparent={true} />*/}
                {/*</mesh>*/}
                <mesh
                    // position={[0, 1, 0]}
                    // rotation={[-90, 0, 0]}
                    position={[0, 0, 0]}
                    rotation={[0, 0, 0]}
                    scale={[15, 15, 15]}
                >
                    <Model/>
                </mesh>
            </NFTMarker>
            <ambientLight/>
        </ARCanvas>
    )
}

export default App
